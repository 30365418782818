import React from "react";
import Layout from "../layout";
import Headline from "../components/Headline";
import TextBlock from "../components/TextBlock";
import { Euro } from "@material-ui/icons";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Container, Row, Col } from "react-bootstrap";
import SEO from "../components/seo";
//import  background  from "../img/customerimg2.jpg";
//import kuva1  from '../img/asjamy1web.png'
//import kuva2  from '../img/asjamy2web.png'


export default function AsiakkaatJaMyynti() {
  // Load images
  // Specify sizing, quality etc. here
  const imgdata = useStaticQuery(graphql`{
  bg: file(relativePath: {eq: "customerimg2.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH, quality: 90)
      fluid(quality: 90, maxWidth: 1920) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  }
`)
  const bgImageData = imgdata.bg;

  return <>
  <SEO title="Asiakkaat" description="Asiakasrekisteri aina saataville. Tuki usealla yhteyshenkilölle ja näin isotkin asiakkaat voidaan hallita järkevästi."/>
  <Layout>
  <Headline HeadIcon={Euro} header="Asiakkaat ja myynti" backgroundImageData={bgImageData}/>
  <Container fluid>
    
    <Row className="text-row">

      <Col >
        <TextBlock
          Icon={Euro}
          Header="Asiakasrekisteri"
          Paragraph="Asiakasrekisteri aina saataville. Tuki usealla yhteyshenkilölle ja näin isotkin asiakkaat voidaan hallita järkevästi."
        ></TextBlock>
        </Col>
        

    <Col md="8">
        <StaticImage src="../../content/images/asjamy1web.png" alt="Kuva" layout="fullWidth"/>
      </Col>
    
      </Row>
      
      <Row className="text-row">
      <Col>
       <TextBlock
          Icon={Euro}
          Header="Myynnin seuranta"
          Paragraph="Puhelut, käynnit ja muut asiakaskontaktit ja sovitut tapaamiset kaikki yhdessä paikassa. Hakutoiminto asiakkaiden postinumerolla, paikkakunnalla tai haluamasi hakuehdon mukaisesti."
        ></TextBlock>
      </Col>

      <Col md="8">
        <StaticImage src="../../content/images/asjamy2web.png" className="kuva" alt="kuva2" layout="fullWidth"/>
      </Col>
      </Row>

      <Row className="text-row">
        <Col>
        <TextBlock
          Icon={Euro}
          Header="Myynnin raportit"
          Paragraph="Laajat raportit eriteltynä myyjittäin ja kategorioittain. Helposti laskettavissa esim. myyntiprovisiot. Erittäin kätevä myös myyjien aktiivisuuden seurannassa."
        ></TextBlock>

        <TextBlock
          Icon={Euro}
          Header="Tarjous"
          Paragraph="Ei enää laskuvirheitä! Siistit pdf-muotoiset tarjoukset suoraan järjestelmästä. Katelaskenta hinnoittelun tueksi. Tarjouksen siirto kätevästi tilaukseksi."
        ></TextBlock>
        <TextBlock
          Icon={Euro}
          Header="Tilaus"
          Paragraph="Järjestelmästä valmis tilaus tarjouksen tiedoilla. Tilausvahvistus-pdf lähettäväksi asiakkaalle."
        ></TextBlock>
      </Col>
      <Col md="8">
        <StaticImage src="../../content/images/asjamy2web.png" className="kuva" alt="kuva2" layout="fullWidth"/>
      </Col>
      </Row>
   
  </Container>
  </Layout>
  </>;
}
